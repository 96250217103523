<template>
  <div class="members">
    <!--    -->
    <header>
      <div class="left">
        <img src="../../assets/images/plus.png">
      </div>
      <div class="right">
        <h4>开通Plus会员</h4>
        <p>开通立享6大会员专属特权，助你快速提升</p>
      </div>
    </header>
    <div class="money">
      <ul class="separate">
        <li>
          <ul class="price">
            <li>永久会员</li>
            <li>¥2000</li>
            <li>枇杷村合伙人</li>
          </ul>
          <ul class="medals">
            <li>占股枇杷村0.1%</li>
            <li>项目分红</li>
            <li>合伙人勋章</li>
            <li>会员权益</li>
          </ul>
        </li>
        <li>
          <ul class="price">
            <li>365天</li>
            <li>¥200</li>
            <li>枇杷村合伙人</li>
          </ul>
          <ul class="medals">
            <li>占股枇杷村0.01%</li>
            <li>项目分红</li>
            <li>合伙人勋章</li>
            <li>会员权益</li>
          </ul>
        </li>
        <li>
          <ul class="price">
            <li>365天</li>
            <li>¥30</li>
            <li>¥0.08一天</li>
          </ul>
          <ul class="medals">
            <li>会员权益</li>
          </ul>
        </li>
      </ul>
      <p>开通后会员有效期至：2023年06月20号 0点</p>
    </div>
    <main>
      <ul class="privilege">
        <li>
          <ul>
            <li>特权项</li>
            <li>
              <img src="../../assets/images/plus.png">
              <span>Plus会员</span>
            </li>
            <li>普通会员</li>
          </ul>
        </li>
        <li>
          <ul>
            <li>站内视频讲解</li>
            <li>
              <i class="el-icon-check"></i>
            </li>
            <li><i class="el-icon-close"></i></li>
          </ul>
        </li>
        <li>
          <ul>
            <li>首次免费技术评估</li>
            <li>
              <i class="el-icon-check"></i>
            </li>
            <li><i class="el-icon-close"></i></li>
          </ul>
        </li>
<!--        <li>-->
<!--          <ul>-->
<!--            <li>Plus会员专属题目</li>-->
<!--            <li>-->
<!--              <i class="el-icon-check"></i>-->
<!--            </li>-->
<!--            <li><i class="el-icon-close"></i></li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li>-->
<!--          <ul>-->
<!--            <li>模拟练习题库</li>-->
<!--            <li>-->
<!--              <span>可选Plus会员精选题库</span>-->
<!--            </li>-->
<!--            <li>仅基础题库</li>-->
<!--          </ul>-->
<!--        </li>-->
        <li>
          <ul>
            <li>Plus会员专属勋章</li>
            <li>
              <i class="el-icon-check"></i>
            </li>
            <li><i class="el-icon-close"></i></li>
          </ul>
        </li>
        <li>
          <ul>
            <li>愿望助力</li>
            <li>
              <span>每天上限500次</span>
            </li>
            <li>每天上限100次</li>
          </ul>
        </li>
        <li>
          <ul>
            <li>更多特权尽情期待</li>
            <li>
              <i class="el-icon-check"></i>
            </li>
            <li><i class="el-icon-close"></i></li>
          </ul>
        </li>
      </ul>
    </main>
    <footer>
      <button>前往ADJUST RD收银台支付</button>
      <p>站长提醒：ADJUST RD收银台是桂阳阿茄思特网络技术有限公司独立收银系统，请放心支付</p>
    </footer>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
  @import "../../assets/style/variables";

  .members {
    width: $page-width;
    margin: 20px auto;

    header {
      display: flex;
      height: 140px;
      margin-bottom: 10px;
      margin-top: 30px;

      .left {
        display: flex;
        flex-shrink: 0;
        width: 37%;
        justify-content: right;

        img {
          width: 85px;
          height: 75px;
          margin-top: 30px;
        }
      }

      .right {
        flex-grow: 1;

        h4 {
          font-size: 30px;
          margin: 30px 0 0 20px;
          color: $primary-color;
          font-weight: normal;
        }

        p {
          font-size: 18px;
          margin: 0 0 0 20px;
        }
      }
    }

    //介绍开会员多少钱一天
    .money {
      .separate {
        height: 250px;
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;

        & > li {
          width: 32%;
          background: $background-color;
          border-radius: 30px;
          box-shadow: $background-shadow;

          &:nth-of-type(2), &:last-of-type {
            ul {
              li {
                &:nth-of-type(2) {
                  color: $font-color;
                }
              }
            }
          }

          ul.price {
            text-align: center;
            margin: 0;
            padding: 0;

            li {
              margin-top: 35px;
              font-size: 18px;

              &:nth-of-type(2) {
                margin-top: 0;
                color: $primary-color;
                font-size: 40px;
              }

              &:last-of-type {
                margin-top: 0;
                color: $font-color-third;
                font-size: 15px;
              }
            }
          }

          // 奖励说明
          ul.medals {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 10px 30px;
            flex-wrap: wrap;
            li {
              text-align: center;
              width: 48%;
              margin-bottom: 10px;
              background-color: $background-color-second;
              border-radius: 20px;
              padding: 5px 10px;
              box-sizing: border-box;
            }
          }
        }
      }

      p {
        margin: 25px 0;
        font-size: 18px;
        color: $primary-color;
      }
    }

    main {
      // height: 455px;
      background: $background-color;
      box-shadow: $background-shadow;
      border-radius: 30px;
      margin-bottom: 25px;

      .privilege {
        padding: 0;
        margin: 0;

        & > li {
          &:nth-of-type(odd) {
            background-color: #F7F7F7;
          }

          ul {
            height: 65px;
            padding: 0;
            display: flex;

            li {
              margin-left: 55px;
              flex-shrink: 0;
              font-size: 18px;
              line-height: 65px;
              &:nth-of-type(1) {
                flex-grow: 1;
              }

              &:nth-of-type(2) {
                text-align: center;
                width: 190px;
                margin-right: 100px;

                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  position: relative;
                  top: -3px;
                  margin-right: 10px;
                }

                i {
                  line-height: 65px;
                  font-size: 38px;
                  color: #F6A528;
                }
              }

              &:last-of-type {
                width: 130px;
                text-align: center;
                margin-right: 115px;
                color: $font-color-third;
                i {
                  line-height: 65px;
                  font-size: 33px;
                  color: #F6A528;
                }
              }
            }
          }
        }
      }
    }

    footer {
      button {
        width: 100%;
        height: 50px;
        background: $background-button-color;
        border-radius: 30px;
        border: 0;
        color: #ffffff;
        font-size: 16px;
      }

      p {
        color: $font-color-third;
        font-size: 12px;
        margin-top: 18px;
        margin-bottom: 50px;
      }
    }
  }
</style>
